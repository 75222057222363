<template>
  <div class="relative z-10 feed-layout">
    <router-view/>
  </div>
</template>

<script>
import {mapActions, mapMutations} from 'vuex';

export default {
  name: 'FeedLayout',

  created() {
    Promise.all([this.fetchIngredients(), this.fetchDiets(), this.fetchSelectedProduct(), this.fetchNutrients()])
        .then(() => {
          this.setLoading(false)
        })
        .catch((err) => {
          console.error(err);
          this.setLoading(true)
        })
  },

  methods: {
    ...mapActions({
      fetchIngredients: 'feed/fetchIngredients',
      fetchDiets: 'feed/fetchDiets',
      fetchSelectedProduct: 'productSelect/fetchSelectedProduct',
      fetchNutrients: 'phase/fetchNutrients',
    }),

    ...mapMutations({
      setLoading: 'setLoading'
    }),
  }
};
</script>

<style scoped>

</style>
